import CustomToast from "@/components/Common/CustomToast"
import { numberWithCommas, pushDataLayerEventHandler, pushEvent, sellerType, showCustomToast, showErrorToast, showSuccessToast } from "./utils"
import { alertSound } from "./constant"

/**
 * Author: Mitesh
 * The function to log socket events.
*/
export const socketLogging = async ({ type, isMobile, lang, firstName=null, end_date=null, serverTime=null }) => {
    if(process.env.NEXT_PUBLIC_DISABLE_SOCKET_LOGGING === "true") {
        return false
    }
    let socketData = {}
    if (firstName) {
        socketData['firstName'] = firstName
    }
    if (end_date) {
        socketData['end_date'] = end_date
    }
    if (serverTime) {
        socketData['serverTime'] = serverTime
    }

    const reqParams = {
        platform: isMobile ? 'm-site' : 'web',
        uniqueId: localStorage.getItem('systemId'),
        socketType: type,
        socketData: JSON.stringify(socketData)
    }

    const URLParams = new URLSearchParams(reqParams).toString()
    try {
        await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/socketlogging?${URLParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                locale: lang,
                Cookie: `i18next=${lang}`,
                authorization: window.localStorage.getItem('authToken') ?? '',
            },
        })
    } catch (e) {
        console.error(e);
    }
}

export const handleFailurePayment = ({
    response,
    auctionData,
    setShowConfirmation,
    setLoading,
    lang,
    auctionDetails
}) => {
    if (auctionData.status === 'upcoming') {
        pushEvent(window, lang, 'Payment_Failure', 'Registration Flow', auctionData, auctionDetails);
    } else if (auctionData.status === 'live') {
        pushEvent(window, lang, 'Payment_Failure', 'PlaceBid Flow', auctionData, auctionDetails);
    }

    setShowConfirmation(false);
    showErrorToast(response.data.message);
    setLoading(false);
};


/**
 * Author: Mitesh
 * The function to generate bread crumb title for group auction details.
*/
export const gdpBreadcrumbsData = (data, lang) => ({
	label: data?.title,
	url: lang == 'ar' ? `/auction-group/${data.auctionId}` : `/en/auction-group/${data.auctionId}`,
})


export const breadcrumbsData = (data, t, lang) => {
	const { city, title, cityId } = data
	const array = [
		{
			label: t('auctionCommon.AUCTION'),
			url: lang == 'ar' ? '/' : '/en/',
		},
	]
	if (city) {
		array.push({
			label: city,
			url: lang == 'ar' ? `/?cityId=${cityId}` : `/en?auction_seller=${sellerType.WASALT}&cityId=${cityId}`,
		})
	}
	if (title) array.push({ label: title })
	return array
}

export const onUpdateData = (newData, setAuctionNewDetails) => {
    setAuctionNewDetails((currentData) => {
        let selectedEndDate;
        if (currentData?.endDate) {
            selectedEndDate = (currentData.endDate > newData?.endDate) ? currentData.endDate : newData.endDate;
        } else {
            selectedEndDate = newData.endDate;
        }

        let selectedServerTimeExtension;
        if (newData?.serverTimeOnExtension && currentData?.serverTimeOnExtension) {
            selectedServerTimeExtension =  newData.serverTimeOnExtension > currentData.serverTimeOnExtension ? newData.serverTimeOnExtension : currentData.serverTimeOnExtension;
        } else {
            selectedServerTimeExtension = currentData?.serverTimeOnExtension;
        }

        if(typeof currentData?.serverTimeOnExtension === 'undefined' || !currentData?.serverTimeOnExtension) {
            selectedServerTimeExtension = newData?.serverTimeOnExtension
        }
        return ({...newData, endDate: selectedEndDate, serverTimeOnExtension: selectedServerTimeExtension})
    })
}

// Function to show bid amount based on auction status
export const getBidAmountByStatus = (resData) => {
    const { bidStartingPrice, highestBid, status } = resData ?? {}
    const commissionTopSectionData = {
        live: { amount: highestBid ? highestBid : bidStartingPrice },
        upcoming: { amount: bidStartingPrice ? bidStartingPrice : null },
        past: { amount: highestBid ? highestBid : bidStartingPrice },
        cancel: { amount: highestBid ? highestBid : bidStartingPrice },
    }
    return commissionTopSectionData[status]?.amount
}

export const manageAuction = async ({socketData, getAuctionDetails, setAuctionNewDetails, visibleHistory, userData, setShowClosedPopup}) => {
    const updatedAuctionData =  await getAuctionDetails()
    setAuctionNewDetails({...updatedAuctionData})
    if ( window.localStorage.getItem('userId') && window.localStorage.getItem('authToken') && socketData?.status?.toLowerCase() == 'past') {
        handleAuctionCompletion({
            visibleHistory,
            userData,
            updatedAuctionData,
            setShowClosedPopup
        });
    } else if (socketData?.status?.toLowerCase() == 'past'){
        setShowClosedPopup('general')
    }
}

const handleAuctionCompletion = ({visibleHistory, userData, updatedAuctionData, setShowClosedPopup}) => {
    let type = 'general'
    if ( visibleHistory?.length > 0 &&
        visibleHistory[0].phoneNumber == (userData?.updatedAuctionData?.phoneNumber || userData?.data?.phoneNumber) &&
        visibleHistory[0].amount >= updatedAuctionData.reservedPrice
    ) {
        type = 'winner';
    } else if (updatedAuctionData?.isActiveBidder) {
        type = 'loser';
    } else if (updatedAuctionData?.transactionStatus === 'refunded') {
        type = 'withdraw'
    } 
    setShowClosedPopup(type)
}

export const handleEvent = (lang, event, auctionDetails, extraData={}) => {
    pushEvent(window, lang, event, 'ADP', auctionDetails, {
        ...extraData,
        source: 'registration',
    })
}

export const handleAuctionGroupIdCheck = (auctionGroupId, auctionNewDetails, displayTabs, isMobile, isPastAuction=false) => {
    if (auctionGroupId) {
        return isPastAuction ? auctionNewDetails?.status === 'past' : auctionNewDetails?.status !== 'past';
    }
    const minTabs = isMobile ? 0 : 1;
    return displayTabs?.length > minTabs && (isPastAuction ? auctionNewDetails?.status == 'past' : auctionNewDetails?.status !== 'past');
};

export const onNotifyMe = ({userData, auctionDetails, lang, token, router, setOpenPrimaryPopup, setShowLoginPopup, setAuctionNewDetails, userDataRes=null, auctionData=null}) => {
    if (window.localStorage.getItem('authToken') && window.localStorage.getItem('userId')) {
        let isAbsherVerified = userDataRes ? userDataRes.data.nationalityId : userData?.data?.nationalityId
        if (isAbsherVerified) {
            auctionRegistration({auctionDetails, lang, token, auctionData, setAuctionNewDetails, router})
        } else {
            setTimeout(() => {
                setOpenPrimaryPopup(true)
            }, 250)
        }
    } else {
        setShowLoginPopup(true)
    }
}

const auctionRegistration = async ({auctionDetails, lang, token, auctionData, setAuctionNewDetails, router}) => {
    let objectToSend = {
        userId: localStorage.getItem('userId'),
        entityId: auctionDetails?.auctionUUID,
        type: 'auction_registration_open',
    }
    const res = await fetch(`${process.env.NEXT_PUBLIC_AUCTION_URL}saveAuction`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            locale: lang,
            cookie: `i18next=${lang}`,
            authorization: token,
        },
        body: JSON.stringify(objectToSend),
    })
    const response = await res.json()
    if (response.status === true) {
        let data1 = auctionData ? { ...auctionData, isAuctionNotified: true } : { ...auctionDetails, isAuctionNotified: true }
        onUpdateData(data1, setAuctionNewDetails)
        if (router.query.login) {
            router.push(router.asPath.replace(/\?.+/, ''))
        }
        window.localStorage.setItem('notify', auctionData ? auctionData.auctionUUID : auctionDetails.auctionUUID)
    } else if (response.status === false) {
        showErrorToast(response.message.error.message)
    }
}

// This function plays an audio notification after a bid is placed.
const playSound = async () => {
    alertSound.stop(); // stopping previous sound if there
    alertSound.play();
};

export const resetBid = ({bidHistoryData, data, visibleHistory, setVisibleHistory, setAuctionHistoryData, setAuctionHistory, UpdateAuctionData, userData, openPrimaryPopup, showConfirmation, showHistory, loading, showDepositPopup, t, lang, setShowDepositPopup, setBidHistoryData, isTimerOver, setIsTimerOver}) => {
    if(bidHistoryData && bidHistoryData?.bid !== data?.highestBid){
      
        let historyData = [...visibleHistory]
        let bid = data?.highestBid ? data.highestBid : data?.bidStartingPrice
        if(!document.hidden){
            playSound()
        }
        if (!(visibleHistory.length > 0 && bidHistoryData?.bid?.amount == visibleHistory[0].amount)) {
            historyData = setHistoryVisibility(bidHistoryData, visibleHistory)
            setVisibleHistory(historyData);
        }
  
        if (parseInt(bidHistoryData?.bid?.amount) >= bid) {
            updateAuctionHistoryData({bidHistoryData, setAuctionHistoryData, setAuctionHistory, UpdateAuctionData, data, userData, openPrimaryPopup, showConfirmation, showHistory, loading, showDepositPopup, t, lang, setShowDepositPopup, historyData})
        }
        if (userData?.data?.phoneNumber != historyData?.[0]?.phoneNumber && !document.hidden) {
            setTimeout(() => {
                showCustomToast(
                        <CustomToast
                            showIcon='new_bid'
                            headerText={t('Details.NEW_BID_RECIEVED')}
                            descriptiveText={`${t('Details.NEW_BID_SAR')} ${numberWithCommas(bidHistoryData.bid.amount)} ${t(
                                'Details.RECIEVED_BY',
                            )} ${bidHistoryData.bid.firstName ?? bidHistoryData.bid.fullName}`}
                        />,
                    'new-bid',
                    lang
                )
            }, 500)
        }
        setBidHistoryData(null)
    }
    // Here we are checking if auction is still live or not and updating timeOver state.
    checkAuctionLiveStatus(data, isTimerOver, setIsTimerOver)
}

const checkAuctionLiveStatus = (data, isTimerOver, setIsTimerOver) => {
    if (isTimerOver?.live) {
        const isAuctionStillLive = data?.status === 'live' && new Date(data?.serverTime) < new Date(data?.endDate);
        setIsTimerOver({
            upcoming: false,
            live: !isAuctionStillLive,
        });
    }
};

const updateAuctionHistoryData = ({bidHistoryData, setAuctionHistoryData, setAuctionHistory, UpdateAuctionData, data, userData, openPrimaryPopup, showConfirmation, showHistory, loading, showDepositPopup, t, lang, setShowDepositPopup, historyData}) => {
            setAuctionHistoryData(bidHistoryData)
            setAuctionHistory(bidHistoryData.bid)
            if(!data?.extendedTimeInMills) {
                // update auction data
                const auctionTime = {
                    serverTime: bidHistoryData?.serverTime, 
                    endDate: bidHistoryData?.auctionEndDate,
                    leftOverMs: bidHistoryData?.leftOverMs,
                    leftOverServerMs: bidHistoryData?.leftOverServerMs
                }
                UpdateAuctionData(
                    bidHistoryData.bid.amount,
                    auctionTime,
                    {...data, activeBidder: bidHistoryData?.activeBidder, status: 'live'},
                )
            }
    if (userData?.data.phoneNumber != bidHistoryData.bid.phoneNumber && !document.hidden) {
        let isAnyPopUpActive = openPrimaryPopup || showConfirmation || showHistory || loading
        if (!isAnyPopUpActive && !document.hidden) {
            if (showDepositPopup) {
                showCustomToast(
                        <CustomToast
                            showIcon='warning'
                            headerText={t('Details.OOPS')}
                            descriptiveText={`${t('auctionPopup.BID_DIDNT_GO_THROUGH')}`}
                        />,
                    'oops',
                    lang
                )
                setShowDepositPopup(false)
            }
            if (historyData?.length > 1 && userData?.data?.phoneNumber && userData?.data?.phoneNumber == historyData[1]?.phoneNumber) {
                showCustomToast(
                        <CustomToast
                            showIcon='error'
                            headerText={t('Details.OUTBID')}
                            descriptiveText={`${t('auctionPopup.YOU_HAVE_BEEN_OUTBID')}`}
                        />,
                    'outbid',
                    lang
                )
            }
        }
    }
}

const setHistoryVisibility = (bidHistoryData, visibleHistory) => {
    const newEntry = {
        ...bidHistoryData?.bid,
        amount: Number(bidHistoryData?.bid?.amount),
        serverTime: bidHistoryData?.serverTime,
        auctionEndDate: bidHistoryData?.auctionEndDate,
    };

    let historyData = [newEntry, ...visibleHistory];

    // Limit the history to the latest 7 entries and sort by amount in descending order
    historyData = historyData
        .slice(0, 7)
        .sort((a, b) => b.amount - a.amount);

    // Update the visible history
    return historyData
};


// Function to handle login scenarios
const handleLoginScenarios = async ({router, auctionDetails, userDataRes, lang, setOpenPrimaryPopup, setShowLoginPopup, token, userData, setAuctionNewDetails}) => {
    if (router.query.login === 'true') {
        if (auctionDetails.status === 'upcoming') {
            handleUpcomingAuctionLogin({router, auctionDetails, userDataRes, lang, setOpenPrimaryPopup, setShowLoginPopup, token, userData, setAuctionNewDetails});
        } else if (auctionDetails.status === 'live') {
            handleLiveAuctionLogin(router, auctionDetails, lang, setOpenPrimaryPopup, setShowDepositPopup);
        }
    }
};

// Function to handle upcoming auction login
const handleUpcomingAuctionLogin = ({router, auctionDetails, userDataRes, lang, setOpenPrimaryPopup, setShowLoginPopup, token, userData, setAuctionNewDetails}) => {
    if (!auctionDetails.isAuctionNotified && !auctionDetails.auctionRegistrationDetails.isRegistrationOpen) {
        pushEvent(window, lang, 'NotifyMe_Login_Success', 'NotifyMe Flow', auctionDetails);
        onNotifyMe({ userData, auctionDetails, lang, token, router, setOpenPrimaryPopup, setShowLoginPopup, setAuctionNewDetails, userDataRes});
    } else if (!auctionDetails.isRegistered && auctionDetails.auctionRegistrationDetails.isRegistrationOpen) {
        pushEvent(window, lang, 'Register_Login_Success', 'Registration Flow', auctionDetails);
        setOpenPrimaryPopup(true);
    }
};

// Function to handle live auction login
const handleLiveAuctionLogin = (router, auctionDetails, lang, setOpenPrimaryPopup, setShowDepositPopup) => {
    pushEvent(window, lang, 'PlaceBid_Login_Success', 'PlaceBid Flow', auctionDetails);
    if (!auctionDetails.isRegistered) {
        setOpenPrimaryPopup(true);
    } else if (auctionDetails.isRegistered) {
        router.push(router.asPath.replace(/\?.+/, ''));
        setShowDepositPopup(true);
    }
};

// Function to handle Absher data
const handleAbsherData = async ({ router, auth, lang, t, setUserData, auctionDetails, userData, token, setOpenPrimaryPopup, setShowLoginPopup , setAuctionNewDetails}) => {
    if (router.query.verified) {

        await PostAbhserData({ lang, t, router, setUserData, auctionDetails, userData, token, setOpenPrimaryPopup, setShowLoginPopup, setAuctionNewDetails});

        if (auctionDetails.status === 'upcoming' && !auctionDetails.isAuctionNotified && !auctionDetails.auctionRegistrationDetails.isRegistrationOpen) {
            return;
        } else {
            setOpenPrimaryPopup(true);
        }
    }
};

// Main function to fetch API data
export const fetchAPI = async ({setGuestUserActivityList, setToken, setUserId, lang, auctionDetails, setUserData, router, setOpenPrimaryPopup, setShowLoginPopup, token, userData, getAuctionHistory, t, isMobile, setAuctionNewDetails}) => {
    const auth = window.localStorage.getItem('authToken');

    setGuestUserActivityList('seen', auctionDetails?.auctionUUID);
    setToken(auth);
    setUserId(window.localStorage.getItem('userId'));

    pushEvent(window, lang, 'Auction_Details_Page_Open', 'ADP', auctionDetails);

    let userDataRes = null;
    if (auth) {
        userDataRes = await getUserDetails(lang, setUserData);
    }

    handleLoginScenarios({router, auctionDetails, userDataRes, lang, setOpenPrimaryPopup, setShowLoginPopup, token, userData, setAuctionNewDetails});
    getAuctionHistory(0, 7);
    await handleAbsherData({ router, auth, lang, t, setUserData, auctionDetails, userData, token, setOpenPrimaryPopup, setShowLoginPopup, setAuctionNewDetails});

    const createScrollHandler = () => {
        return () => handleScroll(isMobile);
    };

    window.addEventListener('scroll', createScrollHandler());
    return () => {
        window.removeEventListener('scroll', createScrollHandler());
    };
};

const PostAbhserData = async ({lang, t, router, setUserData, auctionDetails, userData, token, setOpenPrimaryPopup, setShowLoginPopup, setAuctionNewDetails}) => {
    if (router?.query.verified) {
        showSuccessToast(t('auctionBidComponent.IAM_VERIFIED_SUCCESSFULL'),"customClass")
        pushDataLayerEventHandler(window, lang, 'iam_verified_successfully', 'adp', {})
        router.push(router.asPath.replace(/\?.+/, ''))
        const user = await getUserDetails(lang, setUserData)
        if (auctionDetails.status == 'upcoming') {
            if (!auctionDetails?.isAuctionNotified && !auctionDetails?.auctionRegistrationDetails?.isRegistrationOpen) {
                pushEvent(window, lang, 'NotifyMe_KYC_Success', 'NotifyMe Flow',auctionDetails)
                onNotifyMe({userData, auctionDetails, lang, token, router, setOpenPrimaryPopup, setShowLoginPopup, setAuctionNewDetails, user})
            } else {
                pushEvent(window, lang, 'Register_KYC_Success', 'Registration Flow', auctionDetails)
            }
        } else if (auctionDetails.status == 'live') {
            pushEvent(window, lang, 'PlaceBid_KYC_Success', 'PlaceBid Flow', auctionDetails)
        }
    } else {
        showErrorToast(t('auctionBidComponent.SOMETHING_WENT_WRONG_IAM'))
    }
}

export const getUserDetails = async (lang, setUserData) => {
  if (!localStorage.getItem('authToken')) {
    return
  }
  try {
    const res = await fetch(`/api/gateway?apiUrl=user/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        Cookie: `i18next=${lang}`,
        authorization: window.localStorage.getItem('authToken') ?? '',
      },
    })
    const response = await res.json()
    setUserData(response?.data)
    return response?.data
  } catch (error) {
    console.error('Failed To Fetch user/profile')
  }
}

const greenStripClose = () => {
    if (document.getElementById('green_strip')) {
        document.getElementById('green_strip').style.display = 'none'
    }
}

const handleScroll = (isMobile) => {
    if (window.scrollY >= 800 && !isMobile) {
        greenStripClose()
    }
    if (window.scrollY >= 600 && isMobile) {
        greenStripClose()
    }
}